import { Slot } from "@/includes/types/Slots.types";
import { InviteLinkApproveType } from "@/includes/logic/InviteLinks/types";
import { FaceControlStructure } from "@/includes/types/FaceControl.types";
import { ApproveBalancer } from "@/includes/logic/ApproveBalancer/types";
import { TargetState } from "@/includes/types/Board.types";

import {
	IMessageTemplate
} from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";
import { MessageEditorWithMediaData } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { UserActivationAction } from 'piramis-base-components/src/shared/modules/BotFeedback/ActivationAction';
import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";

export type ChannelInfo = {
	id: number,
	title: string,
	username?: string
	members_count: number
	linked_chat_id: number | null
	linked_chat_username: string
	license_type: number
	license_time: string
	avatar: string,
	state: TargetState
	test_state: TestChannelStateCode
	limited_subscription_type: LimitedSubscriptionType
	licence_expired: boolean
	free_licence: boolean
	config_version: number
	config: ChannelConfig
}

export type SlimChannelInfo = Omit<ChannelInfo, 'config'>

export type ChannelInfoStatistics = Omit<ChannelInfo, 'config'> & ChannelStatistics

export type ChannelStatistics = {
	summary_stat?: SummaryStat
	detailed_stat: Array<DetailedStat>
}

export type SummaryStat = {
	first_enter: number
	first_leave: number
	all_enter: number
	all_leave: number
}

export type DetailedStat = {
	channel_id: number
	time: string
} & SummaryStat

export type ChannelConfig = {
	auto_attack_mode_count: number
	auto_attack_mode_interval: number
	kick_deleted_accounts: boolean
	pm_reports: boolean
	replace_channel_forwards: boolean
	disable_join_to_group: boolean
	replace_channel_list: BlackWhiteListItem<string>
	replace_user_forwards: boolean
	album_post_style: string
	media_post_style: string
	text_post_style: string
	slots: Array<Slot>
	goodbuy_message: Array<IMessageTemplate>
	goodbuy_action?: UserActivationAction | null
	limited_subscription_type: LimitedSubscriptionType
	limited_subscription_config?: LimitedSubscriptionConfig | null
	auto_accepted_user_message: Array<IMessageTemplate>
	approve: InviteLinkApproveType
	user_accept_action?: UserActivationAction | null
	user_reject_action?: UserActivationAction | null
	user_captcha_success_action?: UserActivationAction | null
	face_control?:FaceControlStructure | null
	force_approve: boolean
	approve_balancer: ApproveBalancer
}

export enum TestChannelStateCode {
	Success = 'Success',
	None = 'None',

	ChannelPermissionDeleteMessageError = 'ChannelPermissionDeleteMessageError',
	ChannelPermissionEditMessageError = 'ChannelPermissionEditMessageError',
	ChannelPermissionAddUsersError = 'ChannelPermissionAddUsersError',
	ChannelPermissionSendMessageError = 'ChannelPermissionSendMessageError',
	ChannelBotNotAdmin = 'ChannelBotNotAdmin',
	ChannelNotFoundError = 'ChannelNotFoundError',

	ConnectedGroupNotFoundError = 'ConnectedGroupNotFoundError',
	ConnectedGroupPermissionSendMessageError = 'ConnectedGroupPermissionSendMessageError',
	ConnectedGroupPermissionPinMessageError = 'ConnectedGroupPermissionPinMessageError',
	ConnectedGroupPermissionDeleteMessageError = 'ConnectedGroupPermissionDeleteMessageError',
	ConnectedGroupPermissionEditMessageError = 'ConnectedGroupPermissionEditMessageError',
	ConnectedGroupBotNotAdmin = 'ConnectedGroupBotNotAdmin',

	UndefinedError = 'UndefinedError',
	ApiError = 'ApiError',
}

export enum LimitedSubscriptionType {
	None = 'None',
	Channel = 'Channel',
	Comments = 'Comments',
	Content = 'Content',
	Sponsorship = 'Sponsorship',
}

export type LimitedSubscriptionNotify = Record<number, UserActivationAction>

export type LimitedSubscriptionConfig = {
	from: string
	to: string
	notify: LimitedSubscriptionNotify | null
	goodbye: UserActivationAction | null
	issue: UserActivationAction | null
	comments_subscription_warning?: Array<MessageEditorWithMediaData>
}
