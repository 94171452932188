







































































































































































































































import { InputSetups } from '../../mixins/input-setups'
import { LimitedSubscriptionType } from '@/includes/types/Channel.types'
import SaveChannelConfig from '@/components/SaveChannelConfig.vue'
import LimitedSubscriptionLinkButtonBuilder from '@/components/ProjectButtons/LimitedSubscriptionLinkButton'

import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'
import { FieldData } from 'piramis-base-components/src/components/Pi/types'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    SaveChannelConfig,
    PageTitle,
    NestedContent,
    MultiMessageEditorWithMediaInput
  },
  data() {
    return {
      EntityTypes,
      StepUnit
    }
  }
})
export default class CommentsSettings extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  messageSetter(args: FieldData) {
    args.setter = value => {
      let messageToSet = value

      if (Array.isArray(args.model[args.key]) && !args.model[args.key].length) {
        if (args.key === 'comments_group_kick_message') {
          messageToSet[0].text = this.$t('comments_group_kick_message_message').toString().trim()
        }

        if (args.key === 'comments_group_mute_message') {
          messageToSet[0].text = this.$t('comments_group_mute_message_message').toString().trim()
        }

        if (args.key === 'comments_warn_message') {
          messageToSet[0].text = this.$t('comments_warn_message_message').toString().trim()
        }
      }

      this.$store.commit('pi/EXEC', {
        'key': args.key,
        'fn': () => {
          this.$set(args.model, args.key, messageToSet)
        },
      })
    }

    return args
  }

  get activeChannel() {
    return this.$store.state.channelsState.activeChannel
  }

  get messageEditorButtons() {
    return this.getNewDefaultButtons({
      moreProButtons: [
        new LimitedSubscriptionLinkButtonBuilder(
          this.$i18n,
          this.getArticlesSelectOptions,
          () => this.activeChannel!.limited_subscription_type === LimitedSubscriptionType.Content
        )
      ]
    })
  }

  destroyed(): void {
    this.$store.dispatch('AdminTemplate/hideSaveConfigButton')
  }

  mounted(): void {
    this.$store.dispatch('AdminTemplate/showSaveConfigButton')
  }
}
